<template>
  <BackofficeBase :loader_prop="loader_prop">
    <h1>מחיקה מתבצעת</h1>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: "BackofficeUnitDelete",
  components: {
    BackofficeBase,
  },
  data() {
    return {
      loader_prop: true,
      view_data: [],
    };
  },
  async mounted() {
    this.id = this.$route.query.id;
    let backendModel = new BackendModel();
    let res = await backendModel.backendRequest(
      "/Api/service/backoffice/delete_unit",
      { id: this.id }
    );
    if (res.data.res == "OK") {
      let point_id = res.data.point_id;
      await this.$router.push({
        path: "/backoffice/point_management",
        query: { id: point_id },
      });
    }
    this.loader_prop = false;
  },
};
</script>

<style scoped>
@import "../../assets/style.css";
</style>

